@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  font-family: "Open Sans", sans-serif !important;
}

body {
  margin: 0;
}

code {
  font-family: "Open Sans", sans-serif !important;
}

a {
  text-decoration: none;
  color: inherit;
}